import { GetSession } from '../helpers';
import { API } from '.'
import { appDefaults, backendURL, DISABLE_API_LOADER, isProxy, PLANYO } from '../config';
import moment from "moment";
import { mapping, reqUrl } from './wrapper.service'
import { resourceErrorFunction, assignUnit, findNumberOfChildren, findAgeOfChildren } from './util.service';
import { bookingTypes } from '../redux/types';
import { siteTypes } from '../constants';
const defaultApiLoaderTime = 100;

/**
 * @function getMpidAndType
 * @description function to fetch mpid and type based on siteid
 * @param {string} siteId 
 * @returns {object} returns objet containing mpid and type
 */
const getMpidAndType = (siteId) => {
    const site = appDefaults.sites.find((s) => s.siteId === siteId);
    return {
        mpid: site ? site.meetingPlaceId : null,
        type: site ? site.type : null,
    };
};

/**
 * @function createURL
 * @description function that forms the API url based on the payload received as the argument
 * @param {object} payload an object that contains all the necessary information required to form the url
 * @returns {string} returns the url string
 */
const createURL = (payload = null) => {
    const langSupportedAPIS = [
        "list_reservations",
        "make_reservation",
        "resource_search"
    ];
    // mpid and type will be used instead of site_id in planyo APIs. This is because now one common json will be used to fetch api_key based on mpid and type for planyo API calls as well.
    // last commit where site_id was used to fetch api_key - 1d540f4de9742f2f24e7c24bdde5da5eeda3d0ec
    let site = GetSession("site");
    site = site ? JSON.parse(site) : null;
    let siteId = payload?.siteId || site?.siteId || null;
    let { mpid, type } = siteId ? getMpidAndType(siteId) : { mpid: null, type: null }
    let meetingPlace = GetSession("meetingPlace");
    meetingPlace = meetingPlace ? JSON.parse(meetingPlace) : null
    // let api_key = meetingPlaceConfig[site?.siteId];
    const language = GetSession("selectedLanguage");
    const params = {
        // resource_id: payload?.id !== 'all' ? (payload?.id || null) : null,
        resource_id: payload?.id || null,
        user_id: payload?.user_id || null,
        method: payload?.method || "resource_search",
        detail_level: payload?.detail_level || null,
        api_key: undefined,
        start_date: payload?.startDate || null,
        end_date: payload?.endDate || null,
        separate_units: payload?.separateUnits || null,
        start_time: payload?.startTime || null,
        end_time: payload?.endTime || null,
        mpid: payload?.mpid || mpid || null,
        // site_id: payload?.siteId || site?.siteId || null,
        assignment1: payload?.assignment1 || null,
        year: payload?.year || null,
        month: payload?.month || null,
        quantity: payload?.quantity || null,
        email: payload?.email || null,
        first_name: payload?.firstName || null,
        last_name: payload?.lastName || null,
        reservation_id: payload?.reservationId || null,
        phone_number: payload?.phone || null,
        phone_prefix: payload?.phonePrefix || null,
        action: payload?.action || null,
        prop_res_number_of_persons: payload?.numberOfPersons || null,
        language: payload?.language || (langSupportedAPIS?.includes(payload?.method) ? language?.toUpperCase() : null),
        country: payload?.countryCode || null,
        list_published_only: payload?.listPublishedOnly || null,
        format: payload?.format || null,
        future_only: payload?.futureOnly || null,
        required_status: payload?.requiredStatus || null,
        property_name: payload?.propertyName || null,
        property_value: payload?.propertyValue || null,
        name: payload?.name || null,
        type: payload?.type || type || null,
        id: payload?.customPropertyId || null, // planyo user id
        value: payload?.value || null,
        new_email: payload?.newEmail || null,
        phone_country_code: payload?.phoneCountryCode || null,
        prop_user_icim_id: payload?.userIcimId || null,
        prop_user_cmd_id: payload?.userCmdId || null,
        rental_prop_cmd_user_id: payload?.rentalPropCmdUserId || null,
        rental_prop_icim_user_id: payload?.rentalPropIcimUserId || null,
        rental_prop_Store: payload?.rentalPropStore || null,
        template: payload?.template || null,
        prop_res_offer_category: payload?.offerCategory === 'none' ? 'or,none' : null || null,
        prop_res_is_b2b: payload?.portal || null,
        rental_prop_children: payload?.rentalPropChildren || null,
        rental_prop_Age_of_children_1: payload?.rentalPropAgeOfChildren1 || null,
        rental_prop_Age_of_children_2: payload?.rentalPropAgeOfChildren2 || null,
        rental_prop_DNI: payload?.rentalPropDNI || null
    };

    let paramsArr = [];
    for (let key in params) {
        if (key === "api_key" || params[key]) {
            paramsArr.push(key + "=" + params[key]);
        }
    }

    return "?" + paramsArr.join("&");
}

/**
 * @function search
 * @description function that process the request payload received in argument, called the createURL function to form the api url,
 * and perform the search query.
 * @param {object} data object that contains the payload to be searched
 * @param {function} dispatch a function to send actions into the redux store
 * @param {*} history 
 * @returns {Promise<object>} for successful query returns the response object and for failed request returns the error object
 */
export const search = (data = { apiloader: true }, dispatch = () => { }, history = null) => {
    if (data?.date) {
        if ((data?.method === 'make_reservation' || data?.method === 'modify_reservation') && !data?.timeIn && data?.resourceType === 'weekly') {
            if (moment(data?.date)?.format("YYYY-MM-DD") === moment()?.format("YYYY-MM-DD")) {
                data["startTime"] = moment(data?.date, "YYYY-MM-DD")?.hour(moment()?.hour())?.minute(moment()?.minute())?.format("YYYY-MM-DDTHH:mm");
            }
            else {
                data["startTime"] = moment(data?.date, "YYYY-MM-DD")?.hour('00')?.minute('00')?.format("YYYY-MM-DDTHH:mm");
            }
        } else {
            data["startTime"] = moment(data?.date, "YYYY-MM-DD")?.hour(data?.timeIn?.split(":")[0])?.minute(data?.timeIn?.split(":")[1])?.second(0)?.millisecond(0)?.format("YYYY-MM-DDTHH:mm");
        }
    }
    if (data?.date) {
        if ((data?.method === 'make_reservation' || data?.method === 'modify_reservation') && !data?.timeOut && data?.resourceType === 'weekly') {
            data["endTime"] = moment(data?.endDate || data?.date, "YYYY-MM-DD")?.hour('23')?.minute('59')?.format("YYYY-MM-DDTHH:mm");
        } else {
            data["endTime"] = moment(data?.endDate || data?.date, "YYYY-MM-DD")?.hour(data?.timeOut?.split(":")[0])?.minute(data?.timeOut?.split(":")[1])?.second(0)?.millisecond(0)?.format("YYYY-MM-DDTHH:mm");
        }
    }

    if (data) {
        let dataProcessingFunction = (data) => {
            data["detail_level"] = null;
            data["lang"] = null;
            data["startDate"] = data["startTime"];
            data["endDate"] = data["endTime"];

            delete data?.startTime;
            delete data?.endTime;
        }
        if (data?.detail_level !== 'no') {
            // data["detail_level"] = data?.detail_level ? 15 : 12;
            data["detail_level"] = data?.detail_level ? data?.detail_level : 12;
        } else {
            dataProcessingFunction(data);
        }
        if (PLANYO && data?.method === 'get_resource_usage') {
            dataProcessingFunction(data);
        }

    }

    if (dispatch && data?.apiloader) !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    return new Promise(async (resolve, reject) => {
        let apiUrl = "";
        let wrapperData;
        if (!PLANYO && !data?.headers?.Planyo) {
            const language = GetSession("selectedLanguage");
            data["language"] = data?.language || language?.toUpperCase();
            wrapperData = mapping(data?.method, { ...data, mpid: JSON.parse(GetSession('meetingPlace'))?.mpid });
            apiUrl = reqUrl(data?.method, wrapperData);
        }
        else {
            let apiPath = `${createURL(data)}`;
            // for suppressing "services" from the main url(without proxy)
            let path = {
                path: "../" + apiPath

            }
            if (isProxy) {
                path = {
                    path: apiPath,
                    baseURL: `${backendURL}/pln/`
                }
            }
            apiUrl = { path: { ...path }, key: apiPath, body: data, options: { headers: data?.headers } || {} };
        }
        await API[wrapperData?.httpMethod || 'get'](apiUrl
        ).then(resp => {
            if (resp && resp?.data?.results) {
                resp.data.results = Object.values(resp?.data?.results).map(r => {
                    //r.name = r?.translated_name || r?.name;
                    if (r && r?.translated_name) {
                        r.name = r.translated_name;
                    }
                    return r;
                })
            }
            else if (resp && resp?.data?.name) {
                resp.data.name = resp?.data?.translated_name || resp?.data?.name;
            }
            // if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
            resolve(resp);
        }).catch((err) => {
            // if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
            reject(err);
        });
    });
}


/**
 * @function findResource
 * @description A function for finding the resource along with its usage(*only when skipUsage parameter is false)
 * @param {object} param0 object that contains the payload required to search the resource
 * @param {function} dispatch a function to send actions into the redux store
 * @param {*} history 
 * @returns {Promise<object>} for successful query returns the response object and for failed request returns the error object
 */
export const findResource = ({ id, year, month, language, category, date, endDate, noOfMonths = 1, skipUsage = false }, dispatch = () => { }, history = false) => {
    !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    return new Promise((resolve, reject) => {
        // resolve();
        search({ id, method: "get_resource_info", language, category, apiloader: false }, dispatch)
            .then(async (resp) => {
                let result = resp?.data;
                let error = result?.is_published === 'false' ? false : true;
                /* for weekly resource that can be booked for less than a week */
                let isDyanmicWeekly = (result?.properties?.rental_starting_days && result?.min_rental_time === '24') ? true : false;
                dispatch({ type: bookingTypes.IS_DYNAMIC_BOOKING, payload: isDyanmicWeekly });
                // setting error in case of invalid resource ID or unpublished resource
                resourceErrorFunction(resp?.errors, dispatch, error);
                if (!skipUsage) {
                    if ((parseFloat(resp?.data?.min_rental_time) > 167) || isDyanmicWeekly) {
                        //commit 53204bf847df68cd63a7968ff980005c758dcfcb - removed the get usage for month API
                        let startTime = moment()?.date(1)?.set('hour', 0)?.set('minute', 0)?.set('second', 0)?.format("YYYY-MM-DDTHH:mm");

                        let endTime = moment(startTime)?.add(noOfMonths, 'month')?.date(1)?.format("YYYY-MM-DDTHH:mm");
                        //    debugger
                        let isSeparateUnit = result?.properties?.rental_starting_days?.length ? true : false;
                        await search({ id, method: "get_resource_usage", language, startTime: startTime, endTime: endTime, separatePeriods: false, separateUnits: isSeparateUnit }, dispatch)
                            .then((usage) => {
                                let allUsage = usage?.data?.usage?.[id];
                                if (allUsage) {
                                    if (result && !result?.usage) { result['usage'] = {}; }
                                    Object?.keys(allUsage)?.forEach(currentYear => {
                                        Object?.keys(allUsage[currentYear])?.forEach(currentMonth => {
                                            let monthYear = moment()?.set('year', currentYear)?.set('month', currentMonth - 1)?.format("YYYY-MM");
                                            if (result) {
                                                result.usage[monthYear] = allUsage[currentYear]?.[currentMonth];
                                            }
                                        })
                                    })
                                }
                                if (result) {
                                    result.isWeeklyResource = true;
                                    dispatch({ type: "resourceType", payload: "weekly" });
                                }
                                let monthArr = result?.usage ? Object?.keys(result?.usage) : [];
                                for (let i = 0; i < noOfMonths; i++) {
                                    // let currentMonth = moment()?.set('year',year)?.set('month',month-1)?.add(i,'M')?.format("YYYY-MM");
                                    //---As this a weekly resource, it should start from the current month upto 3 months, so month and year are not taken into consideration---
                                    let currentMonth = moment()?.add(i, 'months')?.format("YYYY-MM");
                                    if (monthArr?.length === 0 || !monthArr?.includes(currentMonth)) {
                                        result = {
                                            ...result,
                                            "usage": {
                                                ...result?.usage,
                                                [currentMonth]: {}
                                            }
                                        }
                                    }
                                }
                                // processing unit usage
                                if (usage?.data?.usage && resp?.data?.properties?.rental_starting_days?.length) {
                                    if (result && !result?.unitUsage) { result['unitUsage'] = {}; }
                                    Object?.keys(usage?.data?.usage)?.forEach(currentUsage => {
                                        if (currentUsage !== id) {
                                            result = {
                                                ...result,
                                                "unitUsage": {
                                                    ...result?.unitUsage,
                                                    [currentUsage]: usage?.data?.usage?.[currentUsage]
                                                }
                                            }
                                        }
                                    })
                                }

                            }).catch((err) => {
                                reject(err);
                            })
                        if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
                        resolve(result);

                    } else {
                        //commit 53204bf847df68cd63a7968ff980005c758dcfcb - removed get usage for month API
                        let startTime = moment()?.set('year', year)?.set('month', month - 1)?.date(1)?.set('hour', 0)?.set('minute', 0)?.set('second', 0)?.format("YYYY-MM-DDTHH:mm");
                        // let startTime = moment()?.set('year', year)?.set('month', month - 1)?.date(1)?.format("YYYY-MM-DDTHH:mm");
                        // let startTime = moment()?.set('year', year)?.set('month', month - 1)?.date(1)?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })?.format("YYYY-MM-DDTHH:MM");
                        let endTime = moment(startTime)?.add(noOfMonths, 'month')?.date(1)?.format("YYYY-MM-DDTHH:mm");

                        await search({ id, method: "get_resource_usage", language, startTime: startTime, endTime: endTime, separatePeriods: false, separateUnits: false }, dispatch)
                            .then((usage) => {
                                let allUsage = usage?.data?.usage?.[id];
                                if (allUsage) {
                                    if (result && !result?.usage) { result['usage'] = {}; }
                                    Object?.keys(allUsage)?.forEach(currentYear => {
                                        Object?.keys(allUsage[currentYear])?.forEach(currentMonth => {
                                            let monthYear = moment()?.set('year', currentYear)?.set('month', currentMonth - 1)?.format("YYYY-MM");
                                            if (result) {
                                                result.usage[monthYear] = allUsage[currentYear]?.[currentMonth];
                                            }
                                        })
                                    })
                                    if (result) {
                                        result.isWeeklyResource = false;
                                    }
                                    dispatch({ type: "resourceType", payload: (parseFloat(result?.min_rental_time) === 0.5 ? "halfhourly" : (parseFloat(result?.min_rental_time) === 1 ? "hourly" : "quarterly")) });
                                }
                                let monthArr = result?.usage ? Object?.keys(result?.usage) : [];
                                for (let i = 0; i < noOfMonths; i++) {
                                    let currentMonth = moment()?.set('year', year)?.set('month', month - 1)?.add(i, 'M')?.format("YYYY-MM");
                                    if (monthArr?.length === 0 || !monthArr?.includes(currentMonth)) {
                                        result = {
                                            ...result,
                                            "usage": {
                                                ...result?.usage,
                                                [currentMonth]: {}
                                            }
                                        }
                                    }
                                }
                            }).catch((err) => {
                                reject(err);
                            });

                        if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
                        resolve(result);
                    }
                } else {
                    if (parseInt(resp?.data?.min_rental_time) > 167 || isDyanmicWeekly) {
                        if (result) {
                            result.isWeeklyResource = true;
                        }
                        dispatch({ type: "resourceType", payload: "weekly" });
                    } else {
                        if (result) {
                            result.isWeeklyResource = false;
                        }
                        dispatch({ type: "resourceType", payload: (parseFloat(result?.min_rental_time) === 0.5 ? "halfhourly" : (parseFloat(result?.min_rental_time) === 1 ? "hourly" : "quarterly")) });
                    }
                    if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
                    resolve(result);
                }
            }).catch((err) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
                reject(err);
            });
    })
};
/**
 * @function findResourceUses
 * @description function for finding the usage of the selected resource
 * @param {object} param0 object that contains the payload required to find the usage of the selected resource
 * @param {*} dispatch a function to send actions into the redux store
 * @param {*} history 
 * @returns {Promise<object>} for successful query returns the response object that contains the resource usage
 *  and for failed request returns the error object
 */
export const findResourceUses = ({ id, date = null, endDate = null }, dispatch = () => { }, history = false) => {
    !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    return new Promise((resolve, reject) => {
        let dt = date ? moment(date) : moment().set('date', 1).set('hour', 0).set('minute', 0).set('second', 1);
        let edt = endDate ? moment(endDate) : dt.clone().add(3, 'months');
        search({ id, method: "get_resource_usage", date: dt.format('YYYY-MM-DD'), endDate: edt.format('YYYY-MM-DD'), timeIn: dt.format('HH:mm'), timeOut: edt.format('HH:mm'), detail_level: 'no' })
            .then((usage) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
                resolve(usage?.data);
            }).catch((err) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
                reject(err);
            });
    })
};

/**
 * @function getEventTimes
 * @description function for finding the selected event timings | usage
 * @param {object} param0 object that contains the ID of the selected event
 * @param {function} dispatch a function to send actions into the redux store
 * @param {*} history 
 * @returns {Promise<object>} for successful query returns the response object that contains the event timing information
 *  and for failed request returns the error object
 */
export const getEventTimes = ({ id }, dispatch = () => { }, history = false) => {
    !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    return new Promise((resolve, reject) => {
        search({ id, method: "get_event_times", format: 'array', futureOnly: true, detail_level: 'no' })
            .then((times) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
                resolve(times?.data);
            }).catch((err) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
                reject(err);
            });
    })
}

/**
 * @function getBookingConfirmation
 * @description function for booking the resource selected by user
 * @param {object} param0 object containing the resource ID and booking object information
 * @param {function} dispatch a function to send actions into the redux store
 * @param {*} history 
 * @returns {Promise<object>} for successful query returns the response object that contains the details 
 * of reservation and returns error object in case of failure
 */
// export const getBookingConfirmation = ({ id, booking, icimId }, isPlanyoCall = false, dispatch = () => { }, history = false) => {
export const getBookingConfirmation = ({ id, booking, icimId, cmdId }, dispatch = () => { }, history = false) => {
    !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    const country = JSON.parse(GetSession('country'))
    let prefix = booking?.userDetails?.phonePrefix?.replace(" ", "-")?.replace("+", "");
    // extract user profile data
    // let userData = {};
    // Object.keys(booking?.userDetails)?.map(current => {
    //     if (current === 'phonePrefix') {
    //         userData = {
    //             ...userData,
    //             phonePrefix: booking?.userDetails?.phonePrefix?.replace(" ", "-")?.replace("+", "")
    //         }
    //     } else if (current === 'store') {
    //         userData = {
    //             ...userData,
    //             rentalPropStore: booking?.userDetails?.store ? encodeURIComponent(booking?.userDetails?.store) : null,
    //         }
    //     } else if (current === 'quantity') {
    //         userData = {
    //             ...userData,
    //             quantity: booking?.reservationSlot?.quantity || 1,
    //         }
    //     } else if (current === 'userType' || current === 'payment') { }
    //     else {
    //         userData = {
    //             ...userData,
    //             [current]: booking?.userDetails?.[current] ? booking?.userDetails?.[current] : null
    //         }
    //     }
    // })
    // console.log(userData);
    let childrenAgeObj = {};
    if (PLANYO) {
        booking?.userDetails?.rental_prop_Age_of_children?.map((current, index) => {
            childrenAgeObj = {
                ...childrenAgeObj,
                [`rentalPropAgeOfChildren${index + 1}`]: current,
            }
        })
    } else {
        childrenAgeObj['rentalPropAgeOfChildren1'] = booking?.userDetails?.rental_prop_Age_of_children?.toString();
    }


    let payloadObject = {
        id,
        method: "make_reservation",
        date: booking?.reservationSlot?.date,
        timeIn: booking?.reservationSlot?.timeIn,
        timeOut: booking?.reservationSlot?.timeOut,
        firstName: booking?.userDetails?.name,
        lastName: booking?.userDetails?.surname,
        email: booking?.userDetails?.email,
        phone: booking?.userDetails?.phone,
        phonePrefix: prefix,
        rentalPropCmdUserId: cmdId || null,
        quantity: booking?.reservationSlot?.quantity || 1,
        endDate: booking?.reservationSlot?.checkOutDate,
        countryCode: country?.code || null,
        resourceType: booking?.resourceType,
        rentalPropIcimUserId: icimId || null,
        rentalPropStore: booking?.userDetails?.store ? encodeURIComponent(booking?.userDetails?.store) : null,
        rentalPropDNI: booking?.userDetails?.DNI,
        rentalPropChildren: findNumberOfChildren(booking?.userDetails?.rental_prop_children),
        // rentalPropAgeOfChildren1: booking?.userDetails?.["rental_prop_Age_of_children_1"] ? booking?.userDetails?.["rental_prop_Age_of_children_1"] : null,
        // rentalPropAgeOfChildren2: booking?.userDetails?.["rental_prop_Age_of_children_2"] ? booking?.userDetails?.["rental_prop_Age_of_children_2"] : null
        ...childrenAgeObj,

        // rentalPropStore: isPlanyoCall && booking?.userDetails?.store ? encodeURIComponent(booking?.userDetails?.store) : null,
        // headers: { Planyo: isPlanyoCall }
    }
    if (booking?.selectedResource?.unit_names && booking?.selectedResource?.properties?.rental_starting_days) {
        let assignedUnit = assignUnit(booking?.reservationSlot?.unit_assignment);
        payloadObject = {
            ...payloadObject,
            ...assignedUnit
        }
    }
    return new Promise((resolve, reject) => {
        search(payloadObject)
            .then((resp) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
                resolve(resp);
            }).catch((err) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
                reject(err);
            });
    })
};

/**
 * @function getModificationConfirmation
 * @description function for making changes in the existing booking
 * @param {object} param0 object containing the resource ID and booking object information
 * @param {function} dispatch a function to send actions into the redux store
 * @param {*} history 
 * @returns {Promise<object>} for successful query returns the response object that contains the details 
 * of modified reservation and returns error object in case of failure
 */
export const getModificationConfirmation = ({ id, booking }, dispatch = () => { }, history = false) => {
    if (dispatch) !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    let payloadObject = {
        reservationId: id,
        method: "modify_reservation",
        date: booking?.reservationSlot?.date,
        timeIn: booking?.reservationSlot?.timeIn,
        timeOut: booking?.reservationSlot?.timeOut,
        quantity: booking?.reservationSlot?.quantity,
        endDate: booking?.reservationSlot?.checkOutDate,
        resourceType: booking?.resourceType,
    }
    if (booking?.reservationSlot?.unit_assignment) {
        let assignedUnit = assignUnit(booking?.reservationSlot?.unit_assignment);
        payloadObject = {
            ...payloadObject,
            ...assignedUnit
        }
    }
    return new Promise((resolve, reject) => {
        search(payloadObject)
            .then((resp) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
                resolve(resp);
            }).catch((err) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
                reject(err);
            });
    })
}

/**
 * @function getCancellationConfirmation
 * @description function for making changes in the existing booking
 * @param {object} param0 object containing the resource ID 
 * @param {function} dispatch a function to send actions into the redux store
 * @param {*} history 
 * @returns {Promise<object>} for successful query returns the response object that contains the details 
 * of cancelled reservation and returns error object in case of failure
 */
export const getCancellationConfirmation = ({ id }, dispatch = () => { }, history = false) => {
    !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    return new Promise((resolve, reject) => {
        search({
            reservationId: id,
            method: "do_reservation_action",
            action: 'User_cancel',
        })
            .then((resp) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
                resolve(resp);
            }).catch((err) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
                reject(err);
            });
    })
}
/**
 * @function getBookingDetails
 * @description function for fetching the reservation details
 * @param {object} param0 object containing the resource ID and language 
 * @param {function} dispatch a function to send actions into the redux store
 * @param {*} history 
 * @returns {Promise<object>} for successful query returns the response object that contains the details 
 * of reservation and returns error object in case of failure
 */
export const getBookingDetails = ({ id, language }, dispatch = () => { }, history = false) => {
    !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    return new Promise((resolve, reject) => {
        search({
            reservationId: id,
            method: "get_reservation_data",
            language
        })
            .then((resp) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
                if (resp?.response_code === 3) {
                    reject({ message: resp?.response_message });
                } else {
                    resolve(resp);
                }
            }).catch((err) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
                reject(err);
            });
    })
}

/**
 * @function getCustomProperty
 * @description fetch the custom property value for the required type and property name
 * @param {object} param0 containing the siteId 
 * @returns {Promise<Object>} response of the api call
 */
export const getCustomProperty = ({ siteId = null, siteType }) => {
    return search({
        method: "get_custom_property",
        type: 'site',
        name: 'hidden_rental_auto_refund_threshold',
        customPropertyId: siteId,
        getPropertyFor: "site",
        category: siteType,
        // headers: { Planyo: true }
    })
}

/**
 * @function getEventUsage
 * @description function for placing the API call for fetching the usage of all the events of a site.
 * @param {object} param0 object containing data like 
 *                        - siteId: ID of the selected Site 
 *                        - startTime: starting date-time from which the event usage is needed
 *                        - endTime: ending date-time upto which the event usage is needed 
 * @returns {Promise<Object>} response of the API call
 */
export const getEventUsage = ({ siteId, startTime, endTime }) => {
    return search({
        siteId: siteId,
        method: "get_resource_usage",
        startTime: startTime,
        endTime: endTime,
        id: 0,
        category: siteTypes.EVENT
    })

}