
import { history } from '../helpers';
import { isServer } from '../helpers/server.helper';


// Selected environment
const enablePlanyo = ["dev", 'qa'];
const env = 'dev';
export const reactEnv = env; //"local"; // env; // to app on localhost, change it with local
export const client = false;  // true for client side, false for server side


// SSR Constants
export const PORT = reactEnv === "local" && client ? 3005 : !client ? 3006 : 3005;
export const backendURL = client ? `http://localhost:${PORT}` : '';  // use this with client side for localhost, comment the line when deploying on server
// export const backendURL = client ? `:${PORT}` : '';  // for client side uncomment when deploying on server

// for client side isProxy will be false and for SSR it will be true
export const isProxy = true; // process.env.REACT_APP_SSR === 'true' ? true : false; 

export const SSR_API_URL = {
    wrapperAuth: `https://${env !== "prod" ? 'test.' : ''}api.ingkacentres.com/services/authenticate`, // using this url in server.js to hit backend
    // wrapper: `http://localhost:5000/api`, //`https://${env !== "prod" ? 'test.' : ''}api.ingkacentres.com`, // using this url in server.js to hit backend
    // wrapper: `https://${(env === "dev" ? "wrapperpoc-dev.azurewebsites.net" : (env !== "prod" ? 'test.api.ingkacentres.com' : "api.ingkacentres.com"))}`, // using this url in server.js to hit backend
    wrapper: `https://${(env === "dev" ? "wrapperdev.azurewebsites.net" : (env !== "prod" ? 'test.api.ingkacentres.com' : "api.ingkacentres.com"))}`, // using this url in server.js to hit backend
    planyo: `https://${env !== "prod" ? env + '.' : ''}backend.booking.livat.com/`, // using this url in server.js to hit backend
    icim: `https://api.account${env !== 'prod' ? "test" : ""}.ingkacentres.com`,  //using this url in server.js to hit icim api
}

const URLs = {
    WEB_APP: `https://${env !== 'prod' ? env + '.' : ''}booking.livat.com`,
    API: (isServer || isProxy) ? `${backendURL}/api/` : ((env === 'dev') ? "" : `https://api.booking.livat.com/${env}/`),
    STORAGE: "https://planyostorage.blob.core.windows.net",
}

// SSR env constant object (it is here because .env file is not working with SSR)
const pr = {
    env: {
        REACT_APP_HOST: URLs?.WEB_APP,
        REACT_APP_BACKEND: URLs?.API,
        // BLOB_STORAGE_URL: URLs?.STORAGE + "/jsonstore/",
        REACT_APP_PLANYO: enablePlanyo.includes(env) ? true : false,
        REACT_APP_WRAPPER_VALIDATION: "../userValidation", // custom node backend api created by Kunal for portal lock to others
        REACT_APP_UNDER_MAINTENANCE: "../underMaintenance",
        REACT_APP_LOGIN_SCREEN: true,
        REACT_APP_LANGUAGE: "en",
        REACT_APP_COUNTRY_NAME: "United Kingdom",
        REACT_APP_COUNTRY_CODE: "GB",
        REACT_APP_COPYRIGHT_TEXT: "Ingka Centres Holding B.V.",
        REACT_APP_COPYRIGHT_YEAR: `2021-${(new Date().getFullYear())}`,
        REACT_APP_GOOGLE_ANALYTICS: "UA-228614426-1",
        REACT_APP_GOOGLE_TAG_MANAGER: "GTM-T4RK28B",
        REACT_APP_SITES: `${env}Sites.json`,
        REACT_APP_ENVIRONMENT: env,
        REACT_APP_PORT: ""
    }
};


// Enabling Google Analytics
const ENABLE_GA = pr.env.REACT_APP_ENVIRONMENT === "qa" ? true : false;

// Google Analystics and Google Tag Manager
export const GOOGLE_ANALYTICS = ENABLE_GA ? pr?.env?.REACT_APP_GOOGLE_ANALYTICS : "";
export const GOOGLE_TAG_MANAGER = ENABLE_GA ? pr?.env?.REACT_APP_GOOGLE_TAG_MANAGER : "";

// Planyo constants
export const WRAPPER_URL = pr?.env?.REACT_APP_BACKEND + "services";
// export const WRAPPER_URL = pr?.env?.REACT_APP_BACKEND;
// export const API_SERVER = pr?.env?.REACT_APP_PLANYO ? pr?.env?.REACT_APP_BACKEND : WRAPPER_URL;
// export const BASE_URL = pr?.env?.REACT_APP_PLANYO ? pr?.env?.REACT_APP_BACKEND : "/booking/v1";
export const BASE_URL = pr?.env?.REACT_APP_PLANYO ? pr?.env?.REACT_APP_BACKEND : "/booking/v2";
// export const API_URL_AUTHENTICATE = isProxy ? "services/authenticate/" : WRAPPER_URL + "authenticate/";
export const PLANYO = pr?.env?.REACT_APP_PLANYO;

export const API_URL_AUTHENTICATE = "/authenticate/";
export const API_URL = BASE_URL;

export const DISABLE_PAYMENT = false;
export const DISABLE_API_LOADER = true;
export const ENABLE_LOCAL_LANGUAGE_ONLY = true;


// API URLs
export const API_URL_VALIDATE = pr?.env?.REACT_APP_WRAPPER_VALIDATION;
export const LOGINSCREEN = pr?.env?.REACT_APP_LOGIN_SCREEN;
export const API_URL_SERVER_MAINTENANCE = pr?.env?.REACT_APP_UNDER_MAINTENANCE;

// ICIM login-route breakdown
export const icimUrlComponent = `https://login.account${env !== 'prod' ? "test" : ""}.ingkacentres.com`


// Data files import statements 
const sites = require(`../data/${pr?.env?.REACT_APP_SITES || "devSites.json"}`);
const domains = require('../data/domains.json');
const language = require('../data/language.json');
const countries = require('../data/countries.json');
const meetingPlace = require('../data/meetingPlace.json');


// Defaults
/// Config file
const urlFrgs = history?.location?.pathname?.toLowerCase()?.split("/");
const urlParameters = urlFrgs && urlFrgs[1] ? urlFrgs[1] : [pr.env.REACT_APP_LANGUAGE + "-" + pr.env.REACT_APP_COUNTRY_CODE];

const getDomain = () => {
    const currentURL = (!isServer ? window?.location?.hostname : "") || pr?.env?.REACT_APP_HOST;
    let domainObj = domains?.find(item => item.domain.includes(currentURL));

    let langCode = language?.find(item => item?.code?.toLowerCase() === urlParameters[0]?.split("-")?.[0]);
    let countrySecondary = countries?.find(item => item?.code?.toLowerCase() === urlParameters[0]?.split("-")?.[1]);
    const countryBasedOnURL = countries.find(item => item?.code === domainObj?.countryCode);
    const defaultCountry = {
        name: pr?.env?.REACT_APP_COUNTRY_NAME,
        code: pr?.env?.REACT_APP_COUNTRY_CODE
    }

    return {
        ...domainObj,
        language: domainObj?.language?.toLowerCase() || langCode?.code?.toLowerCase() || pr?.env?.REACT_APP_LANGUAGE,
        country: countryBasedOnURL || countrySecondary || defaultCountry,
        meetingPlace: meetingPlace?.find(mp => (domainObj?.mpids ? domainObj?.mpids?.includes(mp?.mpid) : domainObj.mpid === mp?.mpid))
    }
}
const selectedDomain = getDomain();

// Defaults values for applications
export const appDefaults = {
    sites: sites,
    ...selectedDomain,
    copyright: {
        text: pr?.env?.REACT_APP_COPYRIGHT_TEXT,
        year: pr?.env?.REACT_APP_COPYRIGHT_YEAR
    },
    host: pr?.env?.REACT_APP_HOST + (pr?.env?.REACT_APP_PORT ? ':' + pr?.env?.REACT_APP_PORT : ''),
    environment: pr?.env?.REACT_APP_ENVIRONMENT,
    timeZone: "Europe/London",
}